var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    _vm._b(
      {
        ref: "form",
        staticClass: "dynamic-form",
        attrs: { rules: _vm.rules, model: _vm.model }
      },
      "el-form",
      _vm.propsConfig,
      false
    ),
    [
      _vm._l(_vm.formData, function(item, index) {
        return [
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !item.hidden,
                  expression: "!item.hidden"
                }
              ],
              key: index,
              attrs: {
                label: item.label,
                "label-width": item.labelWidth,
                prop: item.valueKey
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function() {
                      return [
                        item.slotLabel
                          ? _vm._t(
                              item.labelKey || "label_" + item.valueKey,
                              null,
                              { item: item }
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              item.type === "input" || item.type === "number"
                ? _c(
                    "el-input",
                    _vm._g(
                      _vm._b(
                        {
                          on: {
                            change: function($event) {
                              return _vm.numberChange(item)
                            }
                          },
                          model: {
                            value: _vm.model[item.valueKey],
                            callback: function($$v) {
                              _vm.$set(_vm.model, item.valueKey, $$v)
                            },
                            expression: "model[item.valueKey]"
                          }
                        },
                        "el-input",
                        _vm.handleInputProps(item),
                        false
                      ),
                      _vm.bindListener(item)
                    ),
                    [
                      item.prepend
                        ? _c("template", { slot: "prepend" }, [
                            _vm._v(_vm._s(item.prepend))
                          ])
                        : _vm._e(),
                      item.append
                        ? _c("template", { slot: "append" }, [
                            _vm._v(_vm._s(item.append))
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                : item.type === "select"
                ? _c(
                    "el-select",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.model[item.valueKey],
                            callback: function($$v) {
                              _vm.$set(_vm.model, item.valueKey, $$v)
                            },
                            expression: "model[item.valueKey]"
                          }
                        },
                        "el-select",
                        item.props,
                        false
                      ),
                      _vm.bindListener(item)
                    ),
                    _vm._l(item.options, function(option, oIndex) {
                      return _c("el-option", {
                        key: oIndex,
                        attrs: { label: option.text, value: option.value }
                      })
                    }),
                    1
                  )
                : item.type === "date" || item.type === "datetime"
                ? _c(
                    "el-date-picker",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            "picker-options": _vm.bindPickerOptions(item)
                          },
                          on: {
                            change: function($event) {
                              return _vm.itemChange(item)
                            }
                          },
                          model: {
                            value: _vm.model[item.valueKey],
                            callback: function($$v) {
                              _vm.$set(_vm.model, item.valueKey, $$v)
                            },
                            expression: "model[item.valueKey]"
                          }
                        },
                        "el-date-picker",
                        _vm.handleDateProps(item),
                        false
                      ),
                      _vm.bindListener(item)
                    )
                  )
                : item.type === "cascader"
                ? _c(
                    "el-cascader",
                    _vm._g(
                      {
                        attrs: {
                          options: item.options,
                          props: item.props,
                          "show-all-levels":
                            item.props && item.props.showAllLevels,
                          filterable: "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.model[item.valueKey],
                          callback: function($$v) {
                            _vm.$set(_vm.model, item.valueKey, $$v)
                          },
                          expression: "model[item.valueKey]"
                        }
                      },
                      _vm.bindListener(item)
                    )
                  )
                : item.type === "select-tree"
                ? _c(
                    "j-select-tree",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { options: item.options },
                          model: {
                            value: _vm.model[item.valueKey],
                            callback: function($$v) {
                              _vm.$set(_vm.model, item.valueKey, $$v)
                            },
                            expression: "model[item.valueKey]"
                          }
                        },
                        "j-select-tree",
                        item.props,
                        false
                      ),
                      _vm.bindListener(item)
                    )
                  )
                : item.type === "radio"
                ? _c(
                    "el-radio-group",
                    _vm._g(
                      _vm._b(
                        {
                          model: {
                            value: _vm.model[item.valueKey],
                            callback: function($$v) {
                              _vm.$set(_vm.model, item.valueKey, $$v)
                            },
                            expression: "model[item.valueKey]"
                          }
                        },
                        "el-radio-group",
                        item.props,
                        false
                      ),
                      _vm.bindListener(item)
                    ),
                    _vm._l(item.options, function(option, oIndex) {
                      return _c(
                        "el-radio",
                        { key: oIndex, attrs: { label: option.value } },
                        [_vm._v(" " + _vm._s(option.label) + " ")]
                      )
                    }),
                    1
                  )
                : item.type === "checkbox"
                ? _c(
                    "el-checkbox-group",
                    _vm._g(
                      _vm._b(
                        {
                          model: {
                            value: _vm.model[item.valueKey],
                            callback: function($$v) {
                              _vm.$set(_vm.model, item.valueKey, $$v)
                            },
                            expression: "model[item.valueKey]"
                          }
                        },
                        "el-checkbox-group",
                        item.props,
                        false
                      ),
                      _vm.bindListener(item)
                    ),
                    _vm._l(item.options, function(option, oIndex) {
                      return _c(
                        "el-checkbox",
                        { key: oIndex, attrs: { label: option.value } },
                        [_vm._v(" " + _vm._s(option.label) + " ")]
                      )
                    }),
                    1
                  )
                : item.type === "slot"
                ? _vm._t(item.valueKey, null, { item: item, model: _vm.model })
                : _vm._e(),
              item.tip
                ? _c("span", { staticClass: "tip" }, [_vm._v(_vm._s(item.tip))])
                : _vm._e()
            ],
            2
          )
        ]
      }),
      _vm._t("actions")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }