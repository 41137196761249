import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import ORG_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogAddEdit from './components/dialog-addEdit';
import { listOrgTree, remove, exportExcel } from '@api/rm/org';
export default {
  name: 'org',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAddEdit: DialogAddEdit
  },
  data: function data() {
    return {
      loading: false,
      filename: __filename,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      tableData: [],
      query: {},
      pagination: {
        use: false
      },
      headers: ORG_LIST,
      table: {
        rowKey: 'orgCd',
        treeProps: {
          children: 'children'
        },
        expandRowKeys: ['01']
      },
      showAddDialog: false,
      // 展示新增/编辑弹窗
      currentClick: {},
      // 当前点击项
      isEdit: false // 是否为编辑

    };
  },
  methods: {
    init: function init() {
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    // 初始化搜索条件
    initFilter: function initFilter() {
      var _this$query, _this$query2, _this$query3;

      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
      this.query.arrayOrgCd = typeof ((_this$query = this.query) === null || _this$query === void 0 ? void 0 : _this$query.arrayOrgCd) === 'string' && (_this$query2 = this.query) !== null && _this$query2 !== void 0 && _this$query2.arrayOrgCd ? [(_this$query3 = this.query) === null || _this$query3 === void 0 ? void 0 : _this$query3.arrayOrgCd] : [];
    },
    // 点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    // 刷新父组件选中的值
    refreshData: function refreshData(data) {
      this.currentClick.orgName = data.orgName;
      this.currentClick.orgCd = data.orgCd;
      this.currentClick.orgNameAbbr = data.orgNameAbbr;
      this.currentClick.orgTypeText = data.orgTypeText;
      this.currentClick.orgIndex = data.orgIndex;
      this.currentClick.enabled = data.enabled;
      this.currentClick.joinSuperActualDate = data.joinSuperActualDate;
      this.currentClick.leaveSuperActualDate = data.leaveSuperActualDate;
    },
    // 点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.$refs.jTable.pageChange(1);
    },
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      this.loading = true;
      var param = {
        page: page,
        query: this.query
      };
      listOrgTree(param).then(function (res) {
        _this.loading = false;
        _this.tableData = res;
      });
    },
    // TODO 点击编辑
    clickEdit: function clickEdit(item) {
      this.isEdit = true;
      this.currentClick = item;
      this.showAddDialog = true;
    },
    // TODO 点击新增
    clickAdd: function clickAdd(item) {
      this.isEdit = false;
      this.currentClick = item;
      this.showAddDialog = true;
    },
    // TODO 点击删除
    clickDelete: function clickDelete(item) {
      var _this2 = this;

      var param = {
        orgCd: item.orgCd
      };
      this.$confirm('确定删除该机构?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        remove(param).then(function () {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.init();
        });
      });
    },
    // 导出
    clickExport: function clickExport() {
      var _this3 = this;

      this.$confirm('确定数据导出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.initFilter();

        var param = {
          query: _this3.query
        };
        exportExcel(param).catch(function (e) {
          _this3.$message({
            message: e,
            type: 'error'
          });
        });
      }).catch(function () {});
    }
  }
};