var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "dialogDrag",
          rawName: "v-dialogDrag.changeParend",
          modifiers: { changeParend: true }
        },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        title: "合同信息",
        modal: false,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "合同预览", name: "tabContract" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "合同信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v(_vm._s(_vm.labelContractCd) + ":")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.contractCd) + " ")
                          ]),
                          _vm.isHaveContractBuyCd
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(_vm._s(_vm.labelBuyContractCd) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.buyContractCd) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("状态:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.contractStateText) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("客户:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.customerName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件类型:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.idTypeText) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件号码:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.identification) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("联系方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.mobile) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 17 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("住址:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.address) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("机构:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.orgName) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("顾问:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.userName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("产品:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.productName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同金额:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.amount))
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("期限(月):")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.timeLimit) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("返还周期(月):")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.returnPeriod) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("年化入金:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.yearAmount) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("开始日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.startDate) + "  "),
                            this.model.leftDay > 0
                              ? _c("span", [
                                  _vm._v(
                                    "(存续:" + _vm._s(_vm.model.holdDay) + "天)"
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("到期日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.endDate) + "  "),
                            this.model.leftDay > 0
                              ? _c("span", [
                                  _vm._v(
                                    "(到期:" + _vm._s(_vm.model.leftDay) + "天)"
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("收款方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.payMethodText) + " ")
                          ])
                        ],
                        1
                      ),
                      _vm.isHaveAnnualRate
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("收益率(%):")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.annualRate))
                                ])
                              ]),
                              _vm.model.floatAnnualRateTo > 0
                                ? _c("el-col", { attrs: { span: 7 } }, [
                                    _c("span", { staticClass: "d2-fwb" }, [
                                      _vm._v("浮动(%):")
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.model.floatAnnualRateFrom) +
                                        " ~ " +
                                        _vm._s(_vm.model.floatAnnualRateTo) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isProductCategorySM
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c(
                                "el-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isProduct1004,
                                      expression: "isProduct1004"
                                    }
                                  ],
                                  attrs: { span: 7 }
                                },
                                [
                                  _c("span", { staticClass: "d2-fwb" }, [
                                    _vm._v("积存方式:")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.model.saveMethodText) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.isHaveExpectEarnings
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(
                                    _vm._s(_vm.model.extLabelExpectEarnings) +
                                      ":"
                                  )
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.extExpectEarnings))
                                ])
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("金价:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.contractGoldPrice) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c("span", { staticClass: "d2-fwb" }, [
                                    _vm._v("克重:")
                                  ]),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        width: "500",
                                        trigger: "click"
                                      }
                                    },
                                    [
                                      _c("contract-gold-weight", {
                                        attrs: {
                                          propsModel: this.model.contractId
                                        }
                                      }),
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "warning"
                                          },
                                          slot: "reference"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.model.contractGoldWeight)
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.isHaveExpectEarnings
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("赎回方式:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.rentRansomTypeText) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("账户类型:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.accountTypeText) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行户名:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.accountName) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行账号:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.accountNo) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行+分行+支行:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.bankName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-2" },
                        [
                          _vm.model.financeConfirmDate != null
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("财务收款确认:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.financeConfirmDate) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.model.moneyConfirmDate != null
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("资金收款确认:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.moneyConfirmDate) + " "
                                )
                              ])
                            : _vm._e(),
                          _vm.model.bankSerialNumber != null
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("流水号:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.bankSerialNumber) + " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同备注:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.remark) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive3,
                    callback: function($$v) {
                      _vm.collapseActive3 = $$v
                    },
                    expression: "collapseActive3"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "核算信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("结算日期:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.accountingInfo.countDate) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("核算操作:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.accountingInfo.goOnProductId
                                    ? "续约"
                                    : "确认 "
                                ) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("结算类型:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.accountingInfo.countTypeText) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("结算方式:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.accountingInfo.applyTypeText) +
                                " "
                            )
                          ]),
                          _vm.accountingInfo.applyType != "04"
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("提取金额:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.accountingInfo.applyBackCash) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isProductCountType &&
                          _vm.accountingInfo.applyType === "02"
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("到期现金:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.accountingInfo.actualEarnings
                                        ? _vm.accountingInfo.actualEarnings
                                        : _vm.accountingInfo.expectEarnings
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.accountingInfo.applyType != "02"
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("权益合计(本金+收益-手续费):")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(
                                    _vm._s(_vm.accountingInfo.countAmountAll)
                                  )
                                ])
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("续约产品:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.accountingInfo.goOnProductName) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("续约合同金额:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.accountingInfo.goOnAmount))
                            ])
                          ]),
                          _vm.showAccountingInfoGoOnEarnings
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("续约收益金额:")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(
                                    _vm._s(_vm.accountingInfo.goOnEarnings)
                                  )
                                ])
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _vm.accountingInfo.applyType != "01"
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("续约金价:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.accountingInfo.goOnContractGoldPrice
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.accountingInfo.applyType != "01"
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("续约克重:")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.accountingInfo.goOnWeight))
                                ])
                              ])
                            : _vm._e(),
                          _vm.isCounterglow
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("手续费:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.accountingInfo.serviceFee) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.accountingInfo.applyType != "01"
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("提取现货合计:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.accountingInfo.applyBackGoldAmount
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.accountingInfo.applyType != "01"
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("提取现货克重:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.accountingInfo.investGoldWeight
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.accountingInfo.applyType != "01"
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("费用扣减方式:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.accountingInfo.feeDeductTypeText
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _vm.accountingInfo.applyType != "01"
                              ? _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("提货费用:")
                                ])
                              : _vm._e(),
                            _vm._v(
                              " " + _vm._s(_vm.accountingInfo.goldFee) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("接受回访:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.accountingInfo.customerVisitClaimText
                                ) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("核算备注:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.accountingInfo.countRemark) + " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.interestInfo.isLast === 1
                ? _c(
                    "el-collapse",
                    {
                      model: {
                        value: _vm.collapseActive4,
                        callback: function($$v) {
                          _vm.collapseActive4 = $$v
                        },
                        expression: "collapseActive4"
                      }
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "付息信息", name: "1" } },
                        [
                          _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("付息日期:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.interestInfo.settlementsDates) +
                                    " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("付息次数:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.interestInfo.interestTimes) +
                                    " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("付息金额:")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.interestInfo.interest))
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _vm.showInterestInfoGoOnEarnings
                                ? _c("el-col", { attrs: { span: 7 } }, [
                                    _c("span", { staticClass: "d2-fwb" }, [
                                      _vm._v("续约收益金额:")
                                    ]),
                                    _c("span", { staticClass: "d2-emphasis" }, [
                                      _vm._v(
                                        _vm._s(_vm.interestInfo.goOnEarnings)
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm.isCounterglow
                                ? _c("el-col", { attrs: { span: 7 } }, [
                                    _c("span", { staticClass: "d2-fwb" }, [
                                      _vm._v("续约租金金额:")
                                    ]),
                                    _c("span", { staticClass: "d2-emphasis" }, [
                                      _vm._v(
                                        _vm._s(_vm.interestInfo.goOnEarnings)
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "附件", name: "tabAttachment" } },
            [
              _c("attachment", {
                ref: "componentAttachment",
                attrs: {
                  "is-attachment-edit": false,
                  "attachment-contract-id": _vm.propsModel,
                  "attachment-type": _vm.attachmentType
                }
              })
            ],
            1
          ),
          _vm.isProductCategorySM
            ? _c(
                "el-tab-pane",
                { attrs: { label: "录音", name: "tabAttachmentAudio" } },
                [
                  _c("attachment-audio", {
                    ref: "componentAttachmentAudio",
                    attrs: {
                      "is-attachment-edit": false,
                      "attachment-contract-id": _vm.propsModel,
                      "attachment-type": _vm.attachmentTypeAudio
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            {
              attrs: { label: "回单凭证", name: "tabAttachmentReceptionBill" }
            },
            [
              _c("attachment", {
                ref: "componentAttachmentReceptionBill",
                attrs: {
                  "is-attachment-edit": false,
                  "attachment-contract-id": _vm.propsModel,
                  "attachment-type": _vm.attachmentTypeReceptionBill
                }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "结算申请", name: "tabAttachmentCount" } },
            [
              _c("attachment", {
                ref: "componentAttachmentCount",
                attrs: {
                  "is-attachment-edit": false,
                  "attachment-contract-id": _vm.propsModel,
                  "attachment-type": _vm.attachmentTypeCount
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }