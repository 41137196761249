import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AUDIT_PROCESS from '@/maps/enum/audit-process';
import COLOR from '@/maps/enum/color';
import STATUS from '@/maps/enum/status/buy';
import PRODUCT_CATEGORY1 from '@/maps/enum/product-category1';
import PRODUCT_CATEGORY2 from '@/maps/enum/product-category2';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import TABLE_LIST from './option/list'; // 表头

import { list, remove } from '@api/crm/buy/buy';
import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogAdd from './components/dialog-add';
import DialogAddHjcz from './components/dialog-add-hjcz';
import DialogAddJxwy from './components/dialog-add-jxwy';
import DialogAddXtxm from './components/dialog-add-xtxm';
import DialogAddSmxm from './components/dialog-add-smxm';
import DialogProduct from './components/dialog-product';
import DialogInfo from '../../dialog-info';
export default {
  name: 'buy-public',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAdd: DialogAdd,
    DialogAddHjcz: DialogAddHjcz,
    DialogAddJxwy: DialogAddJxwy,
    DialogAddXtxm: DialogAddXtxm,
    DialogInfo: DialogInfo,
    DialogProduct: DialogProduct,
    DialogAddSmxm: DialogAddSmxm
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {},
      pagination: {},
      process: AUDIT_PROCESS.NEW.value,
      //当前审核流程
      query: {
        arrayProductCategory1: [PRODUCT_CATEGORY1.HJ, PRODUCT_CATEGORY1.JJS, PRODUCT_CATEGORY1.YXHH, PRODUCT_CATEGORY1.XT, PRODUCT_CATEGORY1.OTHER] //非私募

      },
      tableData: [],
      showAddDialog: false,
      // 展示新增/编辑弹窗
      showAddHjczDialog: false,
      // 展示黄金出租新增/编辑弹窗
      showAddJxwyDialog: false,
      // 展示金享无忧新增/编辑弹窗
      showAddXtxmDialog: false,
      // 展示信托项目新增/编辑弹窗
      showAddSmxmDialog: false,
      // 展示信黄金回收项目新增/编辑弹窗
      showInfoDialog: false,
      // 展示信息
      showAddProductDialog: false,
      // 展示产品选择
      showTypeJDXM: false,
      // 展示产品选择
      currentRow: {},
      // 当前点击行
      canEditStatus: [STATUS.SAVE.value, STATUS.AUDIT_FAIL.value, STATUS.DEAL_FAIL.value, STATUS.FINANCE_AUDIT_FAIL.value, STATUS.COUNT_AUDIT_FAIL.value] //可以编缉的状态

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        process: this.process,
        page: page,
        query: this.query
      };
      this.loading = true;
      list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    //点击新增选择产品
    clickAddSelectProduct: function clickAddSelectProduct() {
      this.showAddProductDialog = true;
    },
    // TODO 点击新增
    clickEdit: function clickEdit() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      console.log(row, 'row');
      this.currentRow = row;

      if (row.productCategory2 == PRODUCT_CATEGORY2.HJCZ) {
        // 黄金出租
        this.showAddHjczDialog = true;
        console.log(1);
      } else if (row.productCategory2 == PRODUCT_CATEGORY2.HJWT || row.productCategory2 == PRODUCT_CATEGORY2.HJXLS) {
        // 共享无忧
        console.log(2);
        this.showAddJxwyDialog = true;
      } else if (row.productCategory1 == PRODUCT_CATEGORY1.XT) {
        // 信托项目
        console.log(4);
        this.showAddXtxmDialog = true;
        this.showTypeJDXM = false;
      } else if (row.productCategory2 == PRODUCT_CATEGORY2.JD) {
        console.log(4); // 金店 与 XTXM一致 邮箱不必填

        this.showAddXtxmDialog = true;
        this.showTypeJDXM = true; // 是否产品JD
      } else if (row.productCategory2 == PRODUCT_CATEGORY2.HJHSSM || row.productCategory2 == PRODUCT_CATEGORY2.GQ) {
        console.log(3); // 黄金回收(私募) || 股权

        this.showAddSmxmDialog = true;
      } else {
        console.log(5);
        this.showAddDialog = true;
      }
    },
    //查看详情
    clickInfo: function clickInfo() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    //新增选择产品确定
    popAddSelectProduct: function popAddSelectProduct(product) {
      this.currentRow = {
        productId: product.productId
      };

      if (PRODUCT_CATEGORY2.HJCZ == product.category2) {
        this.showAddHjczDialog = true;
      } else if (PRODUCT_CATEGORY2.HJWT == product.category2 || PRODUCT_CATEGORY2.HJXLS == product.category2) {
        this.showAddJxwyDialog = true;
      } else if (PRODUCT_CATEGORY1.XT == product.categroy1) {
        this.showAddXtxmDialog = true;
      } else if (PRODUCT_CATEGORY2.SMXM == product.category2 || PRODUCT_CATEGORY2.GQJJ == product.category2) {
        this.showAddSmxmDialog = true;
      } else {
        this.showAddDialog = true;
      }
    },
    setStatusColor: function setStatusColor(status) {
      //合同状态颜色
      var className = '';

      switch (status) {
        case STATUS.SAVE.value:
          className = COLOR.BLUE;
          break;

        case STATUS.SUBMIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.AUDIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.COUNT_AUDIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.DEAL.value:
          className = '';
          break;

        case STATUS.SUSPEND.value:
          className = '';
          break;

        case STATUS.PAYED.value:
          className = '';
          break;

        case STATUS.AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.DEAL_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.WAIT_FOR_FINANCE.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_AUDIT.value:
          className = COLOR.RED;
          break;

        case STATUS.COUNT_AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.DEAL_RETURN.value:
          className = COLOR.RED;
          break;

        case STATUS.RESERVE_BUY.value:
          className = COLOR.GREEN;
          break;
      }

      return className;
    }
  },
  created: function created() {}
};