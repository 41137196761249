var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-scrollbar", [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.formModel,
                  rules: _vm.rules,
                  "label-width": "100px",
                  size: "mini"
                }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "产品名称", prop: "productName" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: _vm.formModel.productName,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "productName", $$v)
                                },
                                expression: "formModel.productName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "产品类型", prop: "productType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "popper-append-to-body": false,
                                  clearable: "",
                                  placeholder: "请选择",
                                  disabled: _vm.isExamine
                                },
                                model: {
                                  value: _vm.formModel.productType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "productType", $$v)
                                  },
                                  expression: "formModel.productType"
                                }
                              },
                              _vm._l(_vm.selectData.productType, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "收款方式", prop: "payMethod" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "popper-append-to-body": false,
                                  multiple: "",
                                  clearable: "",
                                  placeholder: "请选择",
                                  disabled: _vm.isExamine
                                },
                                model: {
                                  value: _vm.formModel.payMethod,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "payMethod", $$v)
                                  },
                                  expression: "formModel.payMethod"
                                }
                              },
                              _vm._l(_vm.selectData.payMethod, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "目录1", prop: "category1" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "popper-append-to-body": false,
                                  clearable: "",
                                  placeholder: "请选择",
                                  disabled: _vm.isExamine
                                },
                                model: {
                                  value: _vm.formModel.category1,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "category1", $$v)
                                  },
                                  expression: "formModel.category1"
                                }
                              },
                              _vm._l(_vm.selectData.category1, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "目录2", prop: "category2" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "popper-append-to-body": false,
                                  clearable: "",
                                  placeholder: "请选择",
                                  disabled: _vm.isExamine
                                },
                                model: {
                                  value: _vm.formModel.category2,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "category2", $$v)
                                  },
                                  expression: "formModel.category2"
                                }
                              },
                              _vm._l(_vm.selectData.category2, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "目录3", prop: "category3" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "popper-append-to-body": false,
                                  clearable: "",
                                  placeholder: "请选择",
                                  disabled: _vm.isExamine
                                },
                                model: {
                                  value: _vm.formModel.category3,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "category3", $$v)
                                  },
                                  expression: "formModel.category3"
                                }
                              },
                              _vm._l(_vm.selectData.category3, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "目录4", prop: "category4" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "popper-append-to-body": false,
                                  clearable: "",
                                  placeholder: "请选择",
                                  disabled: _vm.isExamine
                                },
                                model: {
                                  value: _vm.formModel.category4,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "category4", $$v)
                                  },
                                  expression: "formModel.category4"
                                }
                              },
                              _vm._l(_vm.selectData.category4, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "合同期限(月)",
                              prop: "selectTimeLimit"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "popper-append-to-body": false,
                                  multiple: "",
                                  disabled: "",
                                  placeholder: "无需填写"
                                },
                                model: {
                                  value: _vm.selectTimeLimit,
                                  callback: function($$v) {
                                    _vm.selectTimeLimit = $$v
                                  },
                                  expression: "selectTimeLimit"
                                }
                              },
                              _vm._l(_vm.selectData.timeLimit, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "返还周期(月)",
                              prop: "selectReturnPeriod"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "popper-append-to-body": false,
                                  multiple: "",
                                  disabled: "",
                                  placeholder: "无需填写"
                                },
                                model: {
                                  value: _vm.selectReturnPeriod,
                                  callback: function($$v) {
                                    _vm.selectReturnPeriod = $$v
                                  },
                                  expression: "selectReturnPeriod"
                                }
                              },
                              _vm._l(_vm.selectData.returnPeriod, function(
                                item
                              ) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "结算属性", prop: "countType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  "popper-append-to-body": false,
                                  clearable: "",
                                  placeholder: "请选择",
                                  disabled: _vm.isExamine
                                },
                                model: {
                                  value: _vm.formModel.countType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "countType", $$v)
                                  },
                                  expression: "formModel.countType"
                                }
                              },
                              _vm._l(_vm.selectData.countType, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "合同编号前缀" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: _vm.formModel.prefix,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "prefix", $$v)
                                },
                                expression: "formModel.prefix"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "购金合同编号前缀",
                              prop: "prefixBuy",
                              "label-width": "130px"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: _vm.formModel.prefixBuy,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "prefixBuy", $$v)
                                },
                                expression: "formModel.prefixBuy"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "资金限额", prop: "moneyLimit" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "0~0",
                                disabled: _vm.isExamine
                              },
                              model: {
                                value: _vm.formModel.moneyLimit,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "moneyLimit", $$v)
                                },
                                expression: "formModel.moneyLimit"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "募集目标金额(万元)",
                              prop: "targetAmount",
                              "label-width": "140px"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: _vm.formModel.targetAmount,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "targetAmount", $$v)
                                },
                                expression: "formModel.targetAmount"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "开售日期", prop: "sellStartDate" }
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择开售日期",
                                disabled: _vm.isExamine,
                                "value-format": "yyyy-MM-dd"
                              },
                              model: {
                                value: _vm.formModel.sellStartDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "sellStartDate", $$v)
                                },
                                expression: "formModel.sellStartDate"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "停售日期", prop: "sellEndDate" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择停售日期",
                                disabled: _vm.isExamine,
                                "value-format": "yyyy-MM-dd"
                              },
                              model: {
                                value: _vm.formModel.sellEndDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "sellEndDate", $$v)
                                },
                                expression: "formModel.sellEndDate"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "合同号生成方式",
                              prop: "isOfficialCd",
                              disabled: _vm.isExamine,
                              "label-width": "120px"
                            }
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1", disabled: _vm.isExamine },
                                model: {
                                  value: _vm.formModel.isOfficialCd,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "isOfficialCd", $$v)
                                  },
                                  expression: "formModel.isOfficialCd"
                                }
                              },
                              [_vm._v("官方合同号")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "0", disabled: _vm.isExamine },
                                model: {
                                  value: _vm.formModel.isOfficialCd,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "isOfficialCd", $$v)
                                  },
                                  expression: "formModel.isOfficialCd"
                                }
                              },
                              [_vm._v("系统自动生成")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "计息方式",
                              prop: "isByDay",
                              disabled: _vm.isExamine
                            }
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1", disabled: _vm.isExamine },
                                model: {
                                  value: _vm.formModel.isByDay,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "isByDay", $$v)
                                  },
                                  expression: "formModel.isByDay"
                                }
                              },
                              [_vm._v("按日计息")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "0", disabled: _vm.isExamine },
                                model: {
                                  value: _vm.formModel.isByDay,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "isByDay", $$v)
                                  },
                                  expression: "formModel.isByDay"
                                }
                              },
                              [_vm._v("合同金额*收益率")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2", disabled: _vm.isExamine },
                                model: {
                                  value: _vm.formModel.isByDay,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "isByDay", $$v)
                                  },
                                  expression: "formModel.isByDay"
                                }
                              },
                              [_vm._v("无固定")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "产品状态", prop: "enabled" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1", disabled: _vm.isExamine },
                                model: {
                                  value: _vm.formModel.enabled,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "enabled", $$v)
                                  },
                                  expression: "formModel.enabled"
                                }
                              },
                              [_vm._v("启用")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "0", disabled: _vm.isExamine },
                                model: {
                                  value: _vm.formModel.enabled,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "enabled", $$v)
                                  },
                                  expression: "formModel.enabled"
                                }
                              },
                              [_vm._v("停用")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "contract" },
                  [
                    _vm._v(" 通用附件要求 "),
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: _vm.isExamine },
                        model: {
                          value: _vm.com,
                          callback: function($$v) {
                            _vm.com = $$v
                          },
                          expression: "com"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "问卷1" } }),
                            _c("el-checkbox", { attrs: { label: "问卷2" } }),
                            _c("el-checkbox", { attrs: { label: "问卷3" } }),
                            _c("el-checkbox", { attrs: { label: "问卷7" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "购销封面" } }),
                            _c("el-checkbox", { attrs: { label: "购销1" } }),
                            _c("el-checkbox", { attrs: { label: "购销2" } }),
                            _c("el-checkbox", { attrs: { label: "购销3" } }),
                            _c("el-checkbox", { attrs: { label: "购销4" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "委托封面" } }),
                            _c("el-checkbox", { attrs: { label: "委托3" } }),
                            _c("el-checkbox", { attrs: { label: "委托4" } }),
                            _c("el-checkbox", { attrs: { label: "委托5" } }),
                            _c("el-checkbox", { attrs: { label: "委托6" } }),
                            _c("el-checkbox", { attrs: { label: "委托20" } }),
                            _c("el-checkbox", { attrs: { label: "委托21" } }),
                            _c("el-checkbox", { attrs: { label: "委托22" } }),
                            _c("el-checkbox", { attrs: { label: "委托23" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "协议封面" } }),
                            _c("el-checkbox", { attrs: { label: "协议2" } }),
                            _c("el-checkbox", { attrs: { label: "协议3" } }),
                            _c("el-checkbox", { attrs: { label: "协议6" } }),
                            _c("el-checkbox", { attrs: { label: "协议8" } }),
                            _c("el-checkbox", { attrs: { label: "协议20" } }),
                            _c("el-checkbox", { attrs: { label: "协议21" } }),
                            _c("el-checkbox", { attrs: { label: "协议22" } }),
                            _c("el-checkbox", { attrs: { label: "协议42" } }),
                            _c("el-checkbox", { attrs: { label: "协议43" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "合同封面" } }),
                            _c("el-checkbox", { attrs: { label: "合同3" } }),
                            _c("el-checkbox", { attrs: { label: "合同4" } }),
                            _c("el-checkbox", { attrs: { label: "合同5" } }),
                            _c("el-checkbox", { attrs: { label: "合同8" } }),
                            _c("el-checkbox", { attrs: { label: "合同9" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "合同11" } }),
                            _c("el-checkbox", { attrs: { label: "合同12" } }),
                            _c("el-checkbox", { attrs: { label: "合同14" } }),
                            _c("el-checkbox", { attrs: { label: "合同15" } }),
                            _c("el-checkbox", { attrs: { label: "合同16" } }),
                            _c("el-checkbox", { attrs: { label: "合同17" } }),
                            _c("el-checkbox", { attrs: { label: "合同18" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书9" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "合格投资者承诺书" }
                            }),
                            _c("el-checkbox", { attrs: { label: "试算截图" } }),
                            _c("el-checkbox", { attrs: { label: "授权单" } }),
                            _c("el-checkbox", { attrs: { label: "付款凭证" } }),
                            _c("el-checkbox", { attrs: { label: "资产证明" } }),
                            _c("el-checkbox", {
                              attrs: { label: "补充协议1" }
                            }),
                            _c("el-checkbox", { attrs: { label: "补充协议2" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "募集说明书17" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "contract" },
                  [
                    _vm._v(" 个人客户额外附件要求 "),
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: _vm.isExamine },
                        on: { change: _vm.changeclick },
                        model: {
                          value: _vm.person,
                          callback: function($$v) {
                            _vm.person = $$v
                          },
                          expression: "person"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "身份证" } }),
                            _c("el-checkbox", { attrs: { label: "银行卡" } }),
                            _c("el-checkbox", { attrs: { label: "协议21" } }),
                            _c("el-checkbox", { attrs: { label: "协议22" } }),
                            _c("el-checkbox", { attrs: { label: "协议23" } }),
                            _c("el-checkbox", { attrs: { label: "问卷3" } }),
                            _c("el-checkbox", { attrs: { label: "问卷4" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "问卷5" } }),
                            _c("el-checkbox", { attrs: { label: "问卷6" } }),
                            _c("el-checkbox", { attrs: { label: "问卷7" } }),
                            _c("el-checkbox", { attrs: { label: "问卷8" } }),
                            _c("el-checkbox", { attrs: { label: "问卷9" } }),
                            _c("el-checkbox", { attrs: { label: "问卷10" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "投资者确认书1" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "投资者确认书2" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书22" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书23" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书24" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书25" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书26" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "个人税收居民身份声明文件26" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "个人税收居民身份声明文件27" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "个人税收居民身份声明文件28" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "回访确认书28" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "回访确认书29" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "合格投资者承诺书" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "投资者基本信息表" }
                            }),
                            _c("el-checkbox", {
                              attrs: {
                                label: "投资者风险匹配告知书及投资者确认函11"
                              }
                            }),
                            _c("el-checkbox", {
                              attrs: {
                                label: "投资者风险匹配告知书及投资者确认函12"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "投资者冷静期回访确认函29" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "投资者冷静期回访确认函30" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "contract" },
                  [
                    _vm._v(" 企业客户额外附件要求 "),
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: _vm.isExamine },
                        model: {
                          value: _vm.org,
                          callback: function($$v) {
                            _vm.org = $$v
                          },
                          expression: "org"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "营业执照" } }),
                            _c("el-checkbox", {
                              attrs: { label: "法人身份证" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "开户许可证" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "股东会决议" }
                            }),
                            _c("el-checkbox", { attrs: { label: "协议22" } }),
                            _c("el-checkbox", { attrs: { label: "协议23" } }),
                            _c("el-checkbox", { attrs: { label: "协议24" } }),
                            _c("el-checkbox", { attrs: { label: "问卷5" } }),
                            _c("el-checkbox", { attrs: { label: "问卷6" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "问卷7" } }),
                            _c("el-checkbox", { attrs: { label: "问卷8" } }),
                            _c("el-checkbox", { attrs: { label: "问卷9" } }),
                            _c("el-checkbox", { attrs: { label: "问卷10" } }),
                            _c("el-checkbox", { attrs: { label: "问卷11" } }),
                            _c("el-checkbox", { attrs: { label: "问卷12" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书24" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书25" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书26" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书27" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书28" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "风险揭示书29" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "机构税收居民身份声明文件28" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "机构税收居民身份声明文件29" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "机构税收居民身份声明文件30" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "机构税收居民身份声明文件31" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "回访确认书32" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "回访确认书33" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "合格投资者承诺书" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "投资者基本信息表4" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "投资者基本信息表5" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: {
                                label: "投资者风险匹配告知书及投资者确认函13"
                              }
                            }),
                            _c("el-checkbox", {
                              attrs: {
                                label: "投资者风险匹配告知书及投资者确认函14"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "投资者冷静期回访确认函34" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "投资者冷静期回访确认函35" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "contract" },
                  [
                    _vm._v(" 黄金实物额外附件要求 "),
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: _vm.isExamine },
                        model: {
                          value: _vm.jewelry,
                          callback: function($$v) {
                            _vm.jewelry = $$v
                          },
                          expression: "jewelry"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [_c("el-checkbox", { attrs: { label: "委托11" } })],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "contract" },
                  [
                    _c("span", { staticClass: "text" }, [_vm._v("* ")]),
                    _vm._v("合同期限/返还周期 "),
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { disabled: _vm.isExamine },
                        model: {
                          value: _vm.checkList,
                          callback: function($$v) {
                            _vm.checkList = $$v
                          },
                          expression: "checkList"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "1/1" } }),
                            _c("el-checkbox", { attrs: { label: "1.5/1.5" } }),
                            _c("el-checkbox", { attrs: { label: "2/2" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "3/3" } }),
                            _c("el-checkbox", { attrs: { label: "3/12" } }),
                            _c("el-checkbox", { attrs: { label: "4/4" } }),
                            _c("el-checkbox", { attrs: { label: "5/5" } }),
                            _c("el-checkbox", { attrs: { label: "7/7" } }),
                            _c("el-checkbox", { attrs: { label: "8/8" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "6/3" } }),
                            _c("el-checkbox", { attrs: { label: "6/6" } }),
                            _c("el-checkbox", { attrs: { label: "6/12" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "12/1" } }),
                            _c("el-checkbox", { attrs: { label: "12/3" } }),
                            _c("el-checkbox", { attrs: { label: "12/4+1" } }),
                            _c("el-checkbox", { attrs: { label: "12/6" } }),
                            _c("el-checkbox", { attrs: { label: "12/12" } }),
                            _c("el-checkbox", { attrs: { label: "12/14" } })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "checkbox" }),
                        _c("div", { staticClass: "checkbox" }),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "13/13" } }),
                            _c("el-checkbox", { attrs: { label: "14/14" } }),
                            _c("el-checkbox", { attrs: { label: "15/15" } }),
                            _c("el-checkbox", { attrs: { label: "17/17" } }),
                            _c("el-checkbox", { attrs: { label: "18/6" } }),
                            _c("el-checkbox", { attrs: { label: "18/12+6" } }),
                            _c("el-checkbox", { attrs: { label: "18/18" } }),
                            _c("el-checkbox", { attrs: { label: "20/20" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "24/3" } }),
                            _c("el-checkbox", { attrs: { label: "24/12" } }),
                            _c("el-checkbox", { attrs: { label: "24/24" } }),
                            _c("el-checkbox", { attrs: { label: "26/12" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "36/1" } }),
                            _c("el-checkbox", { attrs: { label: "36/3" } }),
                            _c("el-checkbox", { attrs: { label: "36/12" } }),
                            _c("el-checkbox", { attrs: { label: "36/36" } }),
                            _c("el-checkbox", { attrs: { label: "38/12" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", { attrs: { label: "60/1" } }),
                            _c("el-checkbox", { attrs: { label: "60/3" } }),
                            _c("el-checkbox", { attrs: { label: "60/60" } }),
                            _c("el-checkbox", { attrs: { label: "72/12" } })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "12+1/12+1" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "12+2/12+2" }
                            }),
                            _c("el-checkbox", { attrs: { label: "24+12/12" } }),
                            _c("el-checkbox", { attrs: { label: "24+36/12" } }),
                            _c("el-checkbox", {
                              attrs: { label: "24+12/24+12" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "24+36/24+36" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "checkbox" },
                          [
                            _c("el-checkbox", {
                              attrs: { label: "36+12/36+12" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "36+24/36+12" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "36+24/36+24" }
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "48+24/48+24" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      !_vm.isExamine
        ? _c(
            "div",
            { staticClass: "bottom" },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.isAdd,
                    loading: _vm.loading
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }