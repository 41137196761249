// 默认表单配置
export var FORM = {
  labelWidth: '70px',
  size: 'mini'
}; // 默认日期组件配置

export var DATE = {
  valueFormat: 'yyyy-MM-dd',
  // 选中值格式化
  rangeSeparator: '至',
  startPlaceholder: '开始日期',
  endPlaceholder: '结束日期'
}; // 默认日期组件配置

export var DATETIME = {
  valueFormat: 'yyyy-MM-dd HH:mm:ss',
  // 选中值格式化
  rangeSeparator: '至',
  startPlaceholder: '开始日期',
  endPlaceholder: '结束日期'
}; // 输入框默认配置

export var INPUT = {
  clearable: true // 是否可清空

};
export var UPLOAD = {
  listType: 'picture-card'
};