





























































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/change/dialog-info.vue"
}
